/* You can add global styles to this file, and also import other style files */
@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";

$blueColor: #006192;

::ng-deep body {
  font-family: 'Allianz Neo', sans-serif;
}

.appWrapper {
  max-width: 1185px;
  margin: 0 auto;

  .mt-40 {
    margin-top: 40px;
  }
}

.teal {
  background-color: teal;
  color: white;
}

.coral {
  background-color: coral;
}

.logo-text {
  margin-left: 10px;
}

.pagingAlignment {
  padding-top: 30px;
}

.standardBlueColor,
.blueColor {
  color: $blueColor;
}

.emptyIcon {
  padding-left: 70px;
}

.alignCenter {
  margin: 0 auto;
  display: block;
}

.pagingClass {
  display: inline-block;
  margin: 0 auto;
}

.showMenu {
  background-color: #f5f5f5;
  color: black;
  transition: all 0.5s ease-in-out;
  height: 0px;
  overflow: hidden;
  z-index: 6000;
  position: absolute;
  width: 100%;

  a {
    display: block;
    font-size: 16px;
    padding: 30px;
    line-height: 24px;
    border-bottom: 1px solid #ececec;
    border-top: 1px solid rgb(236, 236, 236);
    transition: max-height 0.5s, overflow 0.5s 0.5s;
    cursor: pointer;
  }
}

.marginMobileHeader {
  margin-left: 32px;
  margin-right: 32px;
}

.marginMobileContent {
  margin-left: 16px;
  margin-right: 16px;
}

.center {
  text-align: center;
}

.align-content-left {
  text-align: left;
}

.blueBGColor {
  background-color: $blueColor !important;
}

.tagsTextFormatting {
  padding-left: 10px;
  font-size: medium;
}

.nx-taglist__list-item>nx-tag,
.nx-taglist__list-item>nx-tag:hover {
  color: white;
  background-color: $blueColor;
}

.nx-taglistCustom {
  white-space: nowrap;
  padding: 0px 14px;
  display: flex;
  align-items: center;
  height: auto;
  max-width: 100%;
  color: white;
  background-color: $blueColor;
  border-radius: 4px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  list-style: none;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.tagsAlignment {
  display: flex !important;
  flex-flow: wrap;
}

.center-alignment {
  justify-content: center;
}

.make-fixed-to-three-rows-on-showmore {
  height: 150px;
  overflow-x: auto;
}

.tagsTextAlignment {
  padding-top: 10px;
  padding-bottom: 10px;
}

.disableListItemStyle {
  list-style-type: none;
}

.formatHeading {
  font-weight: bold;
  text-align: right;
}

.greyColor {
  color: #767676 !important;
}

// ------------------ Media Queries to achieve responsiveness -------------------- //

//breakpoints
$breakpoint-zero: 0px;
$breakpoint-xs: 350px;
$breakpoint-sm: 702px;
$breakpoint-md: 732px;
$breakpoint-lg: 900px;
$breakpoint-xl: 1280px;

// Header margin set on mobile device
@media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-xl) {
  .headerMargin {
    margin-left: -10px !important;
    margin-right: -10px !important;
    position: relative;
  }

  //dropdown menu on public component
  .menuAlignment a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // breadcrumb media query
  .breadcrumbAlignment {
    margin-left: 22px !important;
  }

  //content alignment media query
  .contentAlignment {
    margin-left: 10px !important;
    margin-right: 13px !important;
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .headerMargin {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  //breadcrum media query
  .breadcrumbAlignment {
    margin-left: 88px !important;
  }

  //content alignment media query
  .contentAlignment {
    margin-left: 88px !important;
    margin-right: 88px !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $breakpoint-lg) {

  //content alignment media query
  .mobileSearchFilter {
    display: none;
  }

  .desktopSearchFilter {
    display: block;
  }

  //dropdown menu on public component
  .menuAlignment {
    height: 0px !important;

    a {
      display: none;
    }
  }

  // desktop div for showing search field name width change
  .desktopSearchFilter-change-width {
    width: 30%;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .alignButtons {
    margin-top: 32px !important;
    margin-left: 30px;
  }

}

//horizontal line at bottom of table
.horizontal-line {
  width: 100%;
  border-bottom: 1px;
}

//pagination
//pagination alignment of location, client and users on desktop and mobile
.pagination-display-inline {
  display: flex;
  position: relative;
}

.paginationContainer {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.pagination-record {
  position: absolute;
}

@media screen and (max-width: 1100px) {
  .pagination-record {
    position: relative;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .mobilePaginationAlignmentItem {
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
  }



}

@media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-lg) {
  .view-list-container {
    display: flex !important;
    margin-left: -64px;
    width: 120px;
    height: 40px;
    margin-bottom: 7%;
    right: 5px !important;
  }

  .view-list-color {

    background: #006192;
    color: #FFFFFF;
    border-radius: 25px;
    border: 1px solid #FFFFFF;
  }

  .oval-shape-view-list {

    border-radius: 25px;
    width: 105px;
    text-align: center;
    font-weight: 900;
    padding-bottom: 26px;
    padding-top: 8px;
    font-size: 17px;
  }
}

@media screen and (min-width: $breakpoint-zero) and (max-width: $breakpoint-lg) {
  .desktopSearchFilter-change-width {
    width: 100%;
  }

  .alignButtons {
    clear: both;
    text-align: center;
    width: 100%;
  }
}

.textColor {
  color: #C2C2C2;
}

// maps overlay control css //
.due-over-due-container {
  width: auto;
  height: 50px;
  background-color: #FFFFFF;
  margin-bottom: 2%;
  display: flex;
  left: 10px !important;
}

.oval-shape {
  border-radius: 25px;
  width: auto;
  height: 0px;
  margin-top: 8px;
  margin-left: 11px;
  text-align: center;
  font-weight: 900;
  padding-bottom: 26px;
  padding-top: 8px;
  font-size: 17px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.over-due-items-color {
  background: #F62459;
  color: white;
}

.due-items-color {
  background: #FAB600;
  color: white;

}

.tagWrap {
  white-space: pre-line;
}

.showMoreMobileAlignment {
  width: 100%;
  font-size: medium;
}

.view-list-container {
  display: none;
}

.oval-shape-info-window-due-count,
.oval-shape-info-window-over-due-count {
  border-radius: 25px;
  border: 1px solid #FFFFFF;
  min-width: 100px;
  height: 0px;
  margin-top: 8px;
  margin-left: 11px;
  text-align: center;
  font-weight: 900;
  padding-bottom: 26px;
  padding-top: 8px;
  color: #FFFFFF;
  font-size: 15px;
}

.info-window-container {
  width: 230px;
  height: 160px;
}

.multiselect-heading {
  font-weight: 600;
}

.selectBox {
  position: relative;
  cursor: pointer;
}

.selectBox select {
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  border: none;
  border-bottom: 1px solid;
  height: 3vh;
}

.chevron-position-adjustment {
  position: absolute;
  left: 92%;
  bottom: -30%;
  z-index: 1;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  height: 32vh;
  overflow: auto;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  cursor: pointer;
}

.align-select-all-clear {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.checkbox-select-all-label {
  display: flex;
  margin: 10px;
  margin-bottom: 28px;
}

.adjust-clear-all-button {
  cursor: pointer;
  position: relative;
  bottom: 2px;
}

.checkbox-label {
  display: flex;
  margin: 10px;
  position: relative;
  top: -10%;
}

.type-to-filter-input {
  border: none;
  border-bottom: 1px solid;
  width: 100%;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.align-filter-heading-with-close-icon {
  position: relative;
  top: -37px;
}


.nx-formfield-hint {
  float: right;
  font-size: 10px;
}

.center-align-filter-modal-button {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

.remove-blank-space-top {
  position: relative;
  top: -30px;
}

.high-light-count {
  font-size: medium;
  padding: 1.0em 0 0 1.5em !important;
}

.change-bg-search-icon:hover {
  background-color: #006192;
  color: #fff;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.filtered-locations-name {
  white-space: nowrap;
  overflow: auto;
}

.filtered-locations-name::-webkit-scrollbar {
  height: 5px;
}

.filtered-locations-name::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filtered-locations-name::-webkit-scrollbar-thumb {
  background: #888;
}

.filtered-locations-name::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.standard-blue {
  color: $blueColor !important;
}

.idle-icon {
  color: red;
}

.center-align {
  display: flex;
  justify-content: center;
}

.increase-font-size {
  font-size: medium;
}

.align-left {
  text-align: left;
}

.container {
  display: flex;
  height: auto;
  gap: 2rem;
  overflow: hidden;
}

.subtitles-text-font-small {
  font-size: 20px;
  font-weight: 300;
}

.bodyStyle {
  font-size: 14px;
}

// for underlining pagination
.nx-pagination__link.is-active{
  text-decoration: underline !important;
}